export function setCartID(state, val) {
  state.cartID = val.id;
  if (val) {
    localStorage.setItem('cartID', val.id);
  } else {
    localStorage.removeItem('cartID');
  }
}

export function setCartContent(state, val) {
  state.cartContent = val;
}

export function setCountries(state, val) {
  state.countries = val;
}
