<template>
  <main-layout>
    <main>

      <ui-loader
        v-if="loading"
        class="self-center mt-9 sm:py-7"
      />

      <div v-else>
        <ui-heading>Nákupní košík</ui-heading>
        <ui-page-section-box
          tight
          :class="{ '-mx-4': this.$isMobile() }"
        >
          <div v-if="cartData?.items?.length > 0">
            <ui-grid>
              <ui-grid-item
                v-for="item of cartData.items"
                :key="item.id"
              >
                <ui-grid-item-col class="w-10">
                  <ui-grid-item-col-content
                    @click="goToProductDetail(item)"
                    class="cursor-pointer"
                    :class="{ 'pr-2': this.$isMobile() }"
                  >
                    <img
                      :src="MEDIA_BASE_URL + item.product.images[0].file"
                      class="max-h-10"
                    >
                  </ui-grid-item-col-content>
                </ui-grid-item-col>
                <ui-grid-item-col class="flex-1 px-4">
                  <ui-grid-item-col-content
                    @click="goToProductDetail(item)"
                    class="cursor-pointer"
                  >
                    <h2 class="font-semibold">
                      {{ item.product.names[$store.getters['common/currentLanguage'].code] }}
                    </h2>
                    <small
                      v-for="(choice, index) of item.variants_choices"
                      :key="choice.id"
                      class="italic font-normal"
                    >
                      {{ choice.text }}<span v-if="index != (item.variants_choices.length - 1)">, </span>
                    </small>
                    <div v-if="this.$isMobile()">
                      <div class="text-sm text-bold">{{ $filters.price(item.total_pricesVAT, $store) }}</div>
                      <div class="text-xs text-gray-400">{{ $filters.price(item.total_prices, $store) }} bez DPH</div>
                    </div>
                  </ui-grid-item-col-content>
                </ui-grid-item-col>
                <ui-grid-item-col class="w-40 pr-4">
                  <ui-grid-item-col-content class="float-right">
                    <div class="flex">
                      <ui-form-input
                        :id="'quantity-' + item.id"
                        v-model:value="item.quantity"
                        type="number"
                        class="w-8 sm:w-14"
                        :min="0"
                        small
                        @change="onQuantityChange(item)"
                      />
                      <div>
                        <ui-button color="clear" small icon="refresh" @click="setItemQuantity(item, item.quantity)" />
                      </div>
                    </div>
                  </ui-grid-item-col-content>
                </ui-grid-item-col>
                <ui-grid-item-col class="w-40" v-if="!this.$isMobile()">
                  <ui-grid-item-col-content>
                    <div class="text-sm text-bold">{{ $filters.price(item.total_pricesVAT, $store) }}</div>
                    <div class="text-xs font-normal text-gray-400">{{ $filters.price(item.total_prices, $store) }} bez DPH</div>
                  </ui-grid-item-col-content>
                </ui-grid-item-col>
                <ui-grid-item-col class="w-10">
                  <ui-grid-item-col-content>
                      <div>
                        <ui-button
                          color="clear"
                          small
                          icon="bin"
                          @click="setItemQuantity(item, 0)"
                          class="text-red-400"
                        />
                      </div>
                  </ui-grid-item-col-content>
                </ui-grid-item-col>
              </ui-grid-item>

              <ui-grid-item>
                <ui-grid-item-col class="w-10" v-if="!this.$isMobile()">
                  <ui-grid-item-col-content>
                  </ui-grid-item-col-content>
                </ui-grid-item-col>
                <ui-grid-item-col  class="w-40" v-if="!this.$isMobile()">
                  <ui-grid-item-col-content>
                  </ui-grid-item-col-content>
                </ui-grid-item-col>
                <ui-grid-item-col class="flex-1 pr-6">
                  <ui-grid-item-col-content
                    class="text-right"
                    :class="{ 'pr-2': this.$isMobile() }"
                  >
                    Celková cena:
                  </ui-grid-item-col-content>
                </ui-grid-item-col>

                <ui-grid-item-col class="w-40">
                  <ui-grid-item-col-content>
                    <div class="text-xl font-semibold text-red-400">{{ $filters.price(cartData.total_pricesVAT, $store) }}</div>
                    <div class="text-xs font-normal text-gray-400">{{ $filters.price(cartData.total_prices, $store) }} bez DPH</div>
                  </ui-grid-item-col-content>
                </ui-grid-item-col>
                <ui-grid-item-col class="w-10" v-if="!this.$isMobile()">
                  <ui-grid-item-col-content>
                  </ui-grid-item-col-content>
                </ui-grid-item-col>

              </ui-grid-item>
            </ui-grid>

            <div
              class="p-8 mb-4"
              :class="{
                'text-center': this.$isMobile(),
                'text-right': !this.$isMobile()
              }"
            >
              <ui-button color="primary" @click="goToShipping()">Pokračovat k objednávce</ui-button>
            </div>
          </div>

          <div v-else>
            <ui-grid>
              <ui-grid-item>
                <ui-grid-item-col class="flex-1 py-4">
                  <ui-grid-item-col-content>
                    Košík je prázdný.
                  </ui-grid-item-col-content>
                </ui-grid-item-col>
              </ui-grid-item>
            </ui-grid>
          </div>
        </ui-page-section-box>
      </div>
    </main>
  </main-layout>
</template>

<script>
import MainLayout from '@/components/layouts/MainLayout.vue';
import uiButton from '@/components/ui/uiButton.vue';
import uiFormInput from '@/components/ui/uiFormInput.vue';
import uiGrid from '@/components/ui/uiGrid.vue';
import uiGridItem from '@/components/ui/uiGridItem.vue';
import uiGridItemCol from '@/components/ui/uiGridItemCol.vue';
import uiGridItemColContent from '@/components/ui/uiGridItemColContent.vue';
import uiHeading from '@/components/ui/uiHeading.vue';
import uiPageSectionBox from "@/components/ui/uiPageSectionBox.vue";
import uiLoader from '@/components/ui/uiLoader.vue';

export default {
  name: 'Cart',

  components: {
    MainLayout,
    uiHeading,
    uiPageSectionBox,
    uiGrid,
    uiGridItem,
    uiGridItemCol,
    uiGridItemColContent,
    uiButton,
    uiFormInput,
    uiLoader
  },

  data: () => ({
    loading: true,
    cartData: null,
    MEDIA_BASE_URL: process.env.VUE_APP_PRODUCTS_MEDIA_BASE_URL
  }),

  created: function() {
    this.reload();
  },

  methods: {
    reload() {
      this.loading = true;
      this.$store.dispatch('cart/loadCartContent').then(() => {
        this.cartData = this.$store.getters['cart/cartContent'];
        this.loading = false;
      });
    },
    goToProductDetail(item) {
      this.$router.push({ name: 'ProductDetail', params: { id: item.product.id } });
    },
    goToShipping() {
      this.$router.push({ name: 'Shipping' });
    },
    setItemQuantity(item, quantity) {
      this.$store.dispatch('cart/changeCartItemQuantity', {
        itemId: item.id,
        quantity: quantity
      }).then(() => {
        this.reload();
      });
    },
    onQuantityChange(item) {
      if (item && item.quantity && item.quantity > 0) {
        this.setItemQuantity(item, item.quantity);
      }
    }
  }
}
</script>
