<template>
  <div class="pb-3.5 pt-9 sm:py-7">
    <div
      class="relative"
      :class="{'text-center sm:text-left': !secondary}"
    >
      <div v-if="this.$slots.actionButton" class="flex items-center absolute right-0">
        <slot name="actionButton"></slot>
      </div>

      <template v-if="!secondary">
        <ui-skeleton v-if="loading" class="inline-block w-64 h-8" color="white" />

        <template v-else-if="!loading">
          <h1 class="flex-grow text-4xl md:text-5xl font-semibold font-title text-gray-700 self-center items-center">
            <slot></slot>
          </h1>
        </template>
      </template>

      <template v-else-if="secondary">
        <ui-skeleton v-if="loading" class="inline-block w-64 h-7" color="white" />

        <h2 v-else-if="!loading" class="flex-grow sm:text-lg font-semibold text-gray-700 self-center"><slot></slot></h2>
      </template>
    </div>

    <p v-if="this.$slots.description" class="text-sm font-medium text-gray-700">
      <slot name="description"></slot>
    </p>
  </div>
</template>

<script>
import uiSkeleton from '@/components/ui/uiSkeleton.vue';

export default {
  name: 'uiHeading',
  components: {
    uiSkeleton
  },

  props: {
    secondary: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  }
}
</script>
