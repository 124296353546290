<template>
  <app-header />
  <div class="w-full md:max-w-6xl mx-auto px-4 md:px-6 2xl:px-14">
    <slot></slot>
  </div>
  <app-footer />
</template>

<script>
import AppHeader from '@/components/AppHeader.vue';
import AppFooter from '@/components/AppFooter.vue';

export default {
  name: 'MainLayout',

  components: {
    AppHeader,
    AppFooter
  }
}
</script>
