<template>
  <div class="flex flex-row-reverse justify-end content-center">
    <label v-if="text" :for="id" class="font-normal">{{ text }}</label>
    <input
      v-bind:value="value"
      v-on:input="$emit('update:value', $event.target.checked)"
      :id="id"
      :name="id"
      type="checkbox"
      :checked="value"
      :disabled="disabled"
      class="
        float-left
        border border-gray-200 rounded-sm
        bg-white-100
        focus:outline-none focus:ring-red-400 focus:border-transparent
        mr-2
      "
    />
  </div>
</template>

<script>
export default {
  name: 'uiFormCheckbox',

  props: {
    id: {
      type: String,
      default: null,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      default: null
    }
  },

  emits: ['update:value']
}
</script>
