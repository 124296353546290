<template>
  <main-layout>
    <main>
      <ui-heading>Online platba</ui-heading>

      <ui-page-section-box>

        <!-- loading -->
        <div v-if="loading" class="flex h-full justify-center content-center">
          <ui-loader class="self-center" />
        </div>

        <!-- loaded -->
        <template v-else>

          <template v-if="onlinePaymentResult == null || onlinePaymentResult.type == null || onlinePaymentResult?.type?.length <= 0">
            <p>V průběhu objednávky jste zvolili online platbu kartou. Kliknutím na tlačítko níže budete přesměrováni na zabezpečenou platební bránu.</p>
          </template>

          <template v-else>

            <!-- online payment result -->
            <template v-if="onlinePaymentResult?.type?.length > 0">

              <!-- online payment error -->
              <div v-if="onlinePaymentResult?.type == 'ERROR'">
                Při online platbě nastala neočekáváná chyba: <b>{{ onlinePaymentResult?.data?.error?.message }}</b>
              </div>

              <!-- online payment uknown -->
              <div v-else>
                Online platba byla ukončena neznámou odpovědí: <b>{{ onlinePaymentResult?.data?.error?.message }}</b>
              </div>
            </template>

          </template>

          <div class="mt-8">
            <ui-button @click.stop="showPaymentModal = true;" color="primary">Přejít k platební bráně</ui-button>
            <ui-button @click.stop="$router.push({ name: 'Cart' })" color="clear" class="ml-4">Zrušit</ui-button>
          </div>

        </template>

      </ui-page-section-box>

    </main>
  </main-layout>

  <payment-modal v-if="showPaymentModal" :show="showPaymentModal" @close="showPaymentModal = false" @payment-result="onPaymentModalResult"></payment-modal>
</template>

<script>
import MainLayout from '@/components/layouts/MainLayout.vue';
import uiHeading from '@/components/ui/uiHeading.vue';
import PaymentModal from '@/components/PaymentModal.vue';
import uiButton from '@/components/ui/uiButton.vue';
import uiLoader from '@/components/ui/uiLoader.vue';
import uiPageSectionBox from "@/components/ui/uiPageSectionBox.vue";

export default {
  name: 'OrderPayment',

  components: {
    MainLayout,
    uiHeading,
    PaymentModal,
    uiButton,
    uiLoader,
    uiPageSectionBox
  },

  data: () => ({
    loading: true,
    showPaymentModal: false,
    onlinePaymentResult: {
      type: null,
      data: null
    },
    cartData: null
  }),

  created: async function() {
    // load card detail - total price needed on payment button
    await this.$store.dispatch('cart/loadCartContent');
    this.cartData = this.$store.getters['cart/cartContent'];

    this.loading = false;
  },

  methods: {
    async onPaymentModalResult(type, data) {
      this.loading = true;
      this.onlinePaymentResult.type = type;
      this.onlinePaymentResult.data = data;

      // payment successful -> redirect to order finished view
      if (this.onlinePaymentResult?.type == 'SUCCESSFUL') {
        await this.$store.dispatch('cart/finishOrder');
        this.$router.push({ name: 'OrderFinished' });
      }

      this.loading = false;
    }
  }
}
</script>
