<template>
  <div>
    <label :for="id" class="block text-sm font-medium text-gray-700">
      {{ labelText }}
    </label>
    <div class="mt-1">
      <select
        id="id"
        name="id"
        v-bind:value="value"
        v-on:input="$emit('update:value', $event.target.value)"
        :required="required"
        :disabled="disabled"
        class="
          appearance-none
          block w-full max-w-lg sm:max-w-xs
          px-3 py-2
          border border-gray-200 rounded-sm
          bg-white-100
          placeholder-gray-400
          focus:outline-none focus:ring-red-400 focus:border-transparent
          sm:text-sm
        "
        :class="{
          'bg-gray-100 text-gray-700 italic': disabled,
        }"
      >
        <option v-if=allowEmpty value="">-</option>
        <option
          v-for="item in values"
          :value="item.id"
          :key="item.id"
        >{{ item.name || item.text }}</option>
      </select>
    </div>
  </div>
</template>

<script>

export default {
  name: 'uiFormSelect',

  props: {
    id: {
      type: String,
      default: null,
      required: true
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      type: String,
      default: null
    },
    values: {
      type: Array,
      default: null
    },
    labelText: {
      type: String,
      default: null
    },
    allowEmpty: {
      type: Boolean,
      default: false
    },
  },

  emits: ['update:value']
}
</script>
