import { createWebHistory, createRouter } from "vue-router";

import Products from '@/views/Products.vue';
import ProductDetail from '@/views/ProductDetail.vue';
import Cart from '@/views/Cart.vue';
import Shipping from '@/views/Shipping.vue';
import OrderAddress from '@/views/OrderAddress.vue';
import OrderPayment from '@/views/OrderPayment.vue';
import OrderFinished from '@/views/OrderFinished.vue';
import OrderSummary from '@/views/OrderSummary.vue';
import NotFound from '@/views/NotFound.vue';

const routes = [
  {
    path: '/',
    name: 'Products',
    component: Products
  },
  {
    path: '/product/:id',
    name: 'ProductDetail',
    component: ProductDetail,
    props: true
  },
  {
    path: '/cart',
    name: 'Cart',
    component: Cart
  },
  {
    path: '/order/shipping',
    name: 'Shipping',
    component: Shipping
  },
  {
    path: '/order/address',
    name: 'OrderAddress',
    component: OrderAddress
  },
  {
    path: '/order/payment',
    name: 'OrderPayment',
    component: OrderPayment
  },
  {
    path: '/order/summary',
    name: 'OrderSummary',
    component: OrderSummary
  },
  {
    path: '/order/order/finished',
    name: 'OrderFinished',
    component: OrderFinished
  },
  {
    path: '/:catchAll(.*)',
    name: '404',
    component: NotFound
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(() => {
  window.scrollTo(0, 0)
});

export default router;
