export function products (state) {
  return state.products;
}

export function productById (state) {
  return (id) => {
    for (let i = 0; i < state.products.length; i++) {
      if (state.products[i].id == id) {
        return state.products[i];
      }
    }
    return null;
  }
}
