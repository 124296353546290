<template>
  <teleport to="#modal">
    <transition name="modalTransition" appear>
      <div v-if="show" class="modal-backdrop">
        <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div class="fixed inset-0 transition-opacity" aria-hidden="true">
            <div @click="this.$emit('close')" class="absolute inset-0 bg-black-900 opacity-50"></div>
          </div>

          <!-- This element is to trick the browser into centering the modal contents. -->
          <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

          <div class="
              inline-block
              align-bottom sm:align-middle
              sm:max-w-lg w-full
              px-4 py-5 sm:p-6 my-20
              bg-white-100 dark:bg-gray-800
              rounded-sm
              text-left
              shadow-xl
              overflow-hidden
              transform transition-all
            "
            role="dialog" aria-modal="true" aria-labelledby="modal-headline"
          >
            <slot></slot>
          </div>
        </div>
      </div>
    </transition>
  </teleport>
</template>

<style scoped>
.modalTransition-enter-active,
.modalTransition-leave-active { @apply transform transition ease-out duration-300; }
.modalTransition-enter-from,
.modalTransition-leave-to { @apply opacity-0; }
.modalTransition-enter-to,
.modalTransition-leave-from { @apply opacity-100; }
.modal-backdrop {
  @apply fixed inset-0 overflow-y-auto backdrop-filter backdrop-blur-xs;

  z-index: 100;
}
</style>

<script>
export default {
  name: 'uiModal',

  emits: ['close'],

  props: {
    show: {
      type: Boolean,
      default: false
    },
  },

  watch: {
    show: function() {
      this.show ? document.body.classList.add('overflow-hidden') : document.body.classList.remove('overflow-hidden');
    }
  },

  created: function() {
  },

  methods: {
    emitClose() {
      if (this.show) {
        this.$emit('close');
      }
    }
  }
}
</script>
