<template>
  <main-layout>
    <main>
      <ui-heading>Objednávka dokončena</ui-heading>

      <ui-page-section-box>

        <p>Vaše objednávka byla úspěšně dokončena.</p>
        <p>Přehled objednávky a podrobné informace o způsobu doručení Vám zašleme emailem.</p>
        <p>Pokud jste zvolili platbu objednávky převodem na bankovní účet, zašleme Vám po zpracování objednávky informaci o způsobu úhrady.</p>
        <p>Daňový doklad (fakturu) Vám zašleme emailem během 3 pracovních dnů od zaplacení.</p>
        <p>Moc děkujeme a věříme, že budete s Karmen spokojeni.</p>

        <div class="mt-8">
          <ui-button @click.stop="goHome();" color="primary" class="">Zpět na seznam produktů</ui-button>
        </div>

      </ui-page-section-box>
    </main>
  </main-layout>
</template>

<script>
import MainLayout from '@/components/layouts/MainLayout.vue';
import uiHeading from '@/components/ui/uiHeading.vue';
import uiPageSectionBox from "@/components/ui/uiPageSectionBox.vue";
import uiButton from '@/components/ui/uiButton.vue';

export default {
  name: 'OrderFinished',

  components: {
    MainLayout,
    uiHeading,
    uiPageSectionBox,
    uiButton
  },

  methods: {
    goHome() {
      this.$router.push({ name: 'Products' });
    }
  }
}
</script>
