import httpClient from '@/httpClient';
// import { throwNetworkUnhandledError } from '@/errors';

export function loadProducts({ commit }) {
  let url = '/products';
  return httpClient.get(url).then(function (response) {
    commit('setProducts', response?.data);
  })
  // .catch((error) => {
  //   return throwNetworkUnhandledError(error);
  // });
}
