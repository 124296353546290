<template>
  <footer>
    <div class="footer__section">
      <div class="footer__col">
        <h5 class="footer__col-title">Stránky</h5>
        <nav>
          <ul class="footer__items">
            <li><a href="https://next.karmen.tech/">Cloud</a></li>
            <li><a href="https://eshop.karmen.tech/">E-shop</a></li>
            <li><a href="https://karmen.tech/cs/karmen-story/">Příběh Karmen</a></li>
            <li><a href="https://karmen.tech/cs/blog/">Blog</a></li>
            <li><a href="https://karmen.tech/cs/docs/">Dokumentace</a></li>
            <li><a href="https://karmen.tech/cs/contact/">Kontakt</a></li>
          </ul>
        </nav>
      </div>

      <div class="footer__col">
        <h5 class="footer__col-title">Naše produkty</h5>
        <nav>
          <ul class="footer__items">
            <li><a href="https://next.karmen.tech/">Karmen Cloud</a></li>
            <li><a href="https://eshop.karmen.tech/">Karmen Pill</a></li>
          </ul>
        </nav>
      </div>

      <div class="footer__col">
        <h5 class="footer__col-title">Kontaktujte nás</h5>
        <nav>
          <ul class="footer__items">
            <li><a class="text-red-400 hover:no-underline" href="mailto:karmen@karmen.tech">karmen@karmen.tech</a></li>
            <li><a class="text-red-400 hover:no-underline" href="tel:+420 222 581 311">+420 222 581 311</a></li>
          </ul>
        </nav>
      </div>

      <div class="footer__col">
        <h5 class="footer__col-title">Sledujte nás</h5>
        <div class="flex space-x-4 mt-2">
          <a
            href="https://github.com/fragaria/karmen"
            title="GitHub profil"
          >
            <ui-icon name="github" class="h-5 w-5" />
          </a>
          <a
            href="https://twitter.com/Karmen3D"
            title="Twitter profil"
            >
            <ui-icon name="twitter" class="h-5 w-5" />
          </a>
          <a
            href="https://facebook.com/karmen3D"
            title="Facebookový profil"
          >
            <ui-icon name="facebook" class="h-5 w-5" />
          </a>
          <a
            href="https://www.youtube.com/channel/UC9kcYa3r0x4MLR47i1flnFw"
            title="Karmen YouTube kanál"
          >
            <ui-icon name="youtube" class="h-5 w-5" />
          </a>
        </div>
      </div>

    </div>

    <div class="border-t">
      <div class="footer__section">
        <div class="">
          <div class="">
            <div class="flex items-center space-x-3">
              <a
                href="https://fragaria.cz"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  alt="Fragaria s.r.o."
                  class="h-10"
                  src="/strawberry.svg"
                />
              </a>
              <div class="font-title text-xs font-normal tracking-widest leading-tight">
                <div>
                  <a
                    href="https://fragaria.cz"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="font-normal"
                  >
                    FRAGARIA &copy; {{currentYear}}
                  </a>
                </div>
                <div class="font-normal">
                  Talk is cheap, show me the code.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<style>
.footer__section {
  @apply md:flex w-full p-6 lg:px-16 m-auto;

  max-width: 60.5rem;
}

.footer__col {
  @apply md:w-1/4;
}

.footer__col-title {
  @apply mt-8 md:mt-0 font-title text-xl uppercase leading-loose;
}

.footer__items {
  @apply pr-12;

  li {
    @apply py-1 text-xs font-normal font-sans;
  }

  a {
    @apply font-normal underline;
  }
}

</style>

<script>
import uiIcon from '@/components/ui/uiIcon.vue';
export default {
  name: 'AppFooter',

  components: {
    uiIcon,
  },

  data: () => ({
    currentYear: new Date().getFullYear()
  })
}
</script>
