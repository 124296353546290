<template>
  <button
    class="hamburger"
    :class="{ 'hamburger--collapse is-active': active }"
    @click="this.$emit('toggle')"
  >
    <span class="hamburger-box">
      <span class="hamburger-inner"></span>
    </span>
  </button>
</template>

<script>
export default {
  name: 'uiHamburger',
  emits: ['toggle'],
  props: {
    active: {
      type: Boolean,
      default: false
    },
  },
}
</script>

<style scoped>

.hamburger {
  @apply
    flex xl:hidden items-center justify-center
    fixed top-0 right-0
    w-12 h-12 md:w-18 md:h-18
    p-3 md:p-5
    bg-black-900
    z-50;

  transition-property: opacity,filter;
  transition-duration: .15s;
  transition-timing-function: linear;
  order: 3;
}

.hamburger-box {
  @apply h-4;
  width: 100%;

  display: block;
  position: relative;
}

.hamburger-inner, .hamburger-inner:after, .hamburger-inner:before {
  width: 100%;
  height: 2px;
  background-color: #fff;
  border-radius: 0;
  position: absolute;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform,-webkit-transform;
  transition-duration: .15s;
  transition-timing-function: ease;
}
.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -1px;
}
.hamburger-inner:before {
  top: -10px;
}
.hamburger-inner:after, .hamburger-inner:before {
  content: "";
  display: block;
}
.hamburger-inner, .hamburger-inner:after, .hamburger-inner:before {
  width: 100%;
  height: 2px;
  background-color: #fff;
  border-radius: 0;
  position: absolute;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform,-webkit-transform;
  transition-duration: .15s;
  transition-timing-function: ease;
}
.hamburger-inner:after {
  bottom: -10px;
}
.hamburger-inner:after, .hamburger-inner:before {
  content: "";
  display: block;
}
.hamburger-inner, .hamburger-inner:after, .hamburger-inner:before {
  width: 100%;
  height: 2px;
  background-color: #fff;
  border-radius: 0;
  position: absolute;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform,-webkit-transform;
  transition-duration: .15s;
  transition-timing-function: ease;
}

.hamburger--collapse.is-active .hamburger-inner {
    transform: translate3d(0,-10px,0) rotate(-45deg);
    transition-delay: .22s;
    transition-timing-function: cubic-bezier(.215,.61,.355,1);
}
.hamburger--collapse .hamburger-inner {
    top: auto;
    bottom: 0;
    transition-duration: .13s;
    transition-delay: .13s;
    transition-timing-function: cubic-bezier(.55,.055,.675,.19);
}

.hamburger--collapse.is-active .hamburger-inner:before {
  background-color: rgb(255, 255, 255);
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  content: "";
  display: block;
  height: 2px;
  position: absolute;
  top: 0px;
  transform: matrix(6.12323e-17, -1, 1, 6.12323e-17, 0, 0);
  transition-delay: 0.16s, 0.25s, 0.25s;
  transition-duration: 0.1s, 0.13s, 0.13s;
  transition-property: top, transform, -webkit-transform;
  transition-timing-function: cubic-bezier(0.33333, 0, 0.66667, 0.33333), cubic-bezier(0.215, 0.61, 0.355, 1), cubic-bezier(0.215, 0.61, 0.355, 1);
  width: 30px;
}
.hamburger--collapse.is-active .hamburger-inner:after {

  background-color: rgb(255, 255, 255);
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  bottom: -10px;
  content: "";
  display: block;
  height: 2px;
  opacity: 0;
  position: absolute;
  top: 0px;
  transition-delay: 0s, 0.22s;
  transition-duration: 0.2s, 0.1s;
  transition-property: top, opacity;
  transition-timing-function: cubic-bezier(0.33333, 0, 0.66667, 0.33333), linear;
  width: 30px;


}
</style>

