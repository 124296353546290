export function currencies (state) {
  return state.currencies;
}

export function languages (state) {
  return state.languages;
}

export function paymentTypes (state) {
  return state.paymentTypes;
}

export function currentLanguageCode () {
  return localStorage.getItem('language');
}

export function currentCurrencyCode () {
  return localStorage.getItem('currency');
}

export function currentLanguage (state) {
  return state.currentLanguage;
}

export function currentCurrency (state) {
  return state.currentCurrency;
}

export function languageByCode (state) {
  return (code) => {
    for (let i = 0; i < state.languages.length; i++) {
      if (state.languages[i].code == code) {
        return state.languages[i];
      }
    }
    return null;
  }
}

export function currencyByCode (state) {
  return (code) => {
    for (let i = 0; i < state.currencies.length; i++) {
      if (state.currencies[i].code == code) {
        return state.currencies[i];
      }
    }
    return null;
  }
}
