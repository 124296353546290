import httpClient from '@/httpClient';

export function loadCartID({ commit }, id) {
  let url = '/cart/id?id=' + id;
  return httpClient.get(url).then(function (response) {
    commit('setCartID', response?.data);
  });
}

export function addToCart({ getters }, payload) {
  let url = '/cart';
  let data = {
    cart_id: getters['cartID'],
    product_id: payload.product_id,
    quantity: payload.quantity,
    variants_choices: payload.variants_choices
  };
  return httpClient.post(url, data).then(function (response) {
    return response;
  });
}

export function loadCartContent({ commit, getters, dispatch }) {
  return dispatch('loadCartID', getters['cartID']).then(() => {
    let url = '/cart/' + getters['cartID'];
    return httpClient.get(url).then(function (response) {
      commit('setCartContent', response?.data);
    });  
  })
}

export function changeCartItemQuantity({ commit }, payload ) {
  let url = '/cart/items/' + payload.itemId;
  return httpClient.patch(url, { quantity: payload.quantity }).then(function (response) {
    commit('setCartContent', response?.data);
  });
}

export function loadCountries({ commit }) {
  let url = '/countries';
  return httpClient.get(url).then(function (response) {
    commit('setCountries', response?.data);
  });
}

export function setCarrier({ getters }, payload) {
  let url = '/cart/carrier';
  return httpClient.put(url, {
    cart_id: getters['cartID'],
    carrier_id: payload.carrier_id,
    extra_data: payload.extra_data
  }).then(function (response) {
    return response;
  });
}

export function setPaymentType({ getters }, payload) {
  let url = '/cart/payment-type';
  return httpClient.put(url, {
    cart_id: getters['cartID'],
    type: payload.payment_type
  }).then(function (response) {
    return response;
  });
}

export function setOrderAddresses({ getters }, payload) {
  let url = '/cart/addresses';
  return httpClient.put(url, {
    cart_id: getters['cartID'],

    // email
    email: payload.email,

    // invoice data
    invoice_vat_id: payload.invoice_vat_id,
    invoice_company: payload.invoice_company,
    invoice_first_name: payload.invoice_first_name,
    invoice_last_name: payload.invoice_last_name,
    invoice_street: payload.invoice_street,
    invoice_zip_code: payload.invoice_zip_code,
    invoice_city: payload.invoice_city,
    invoice_country_id: payload.invoice_country_id,

    // delivery data
    delivery_as_invoice: payload.delivery_as_invoice,
    delivery_first_name: payload.delivery_first_name,
    delivery_last_name: payload.delivery_last_name,
    delivery_street: payload.delivery_street,
    delivery_zip_code: payload.delivery_zip_code,
    delivery_city: payload.delivery_city,
    delivery_country_id: payload.delivery_country_id,
    delivery_delivery_phone: payload.delivery_delivery_phone

  }).then(function (response) {
    return response;
  });
}

export function createPaymentIntent({ getters }) {
  let url = '/cart/' + getters['cartID'] + '/create-payment-intent';
  return httpClient.post(url).then(function (response) {
    return response?.data;
  });
}

export function finishOrder({ getters }) {
  let url = '/cart/' + getters['cartID'] + '/finish-order';
  return httpClient.post(url).then(function (response) {
    return response?.data;
  });
}
