<template>
  <div class="loader">
    <ui-icon name="animate-spin-circle" :class="color" class="animate-spin h-12 w-12" />
    <div v-if="text" class="text">
      {{ text }}
    </div>
  </div>
</template>

<style scoped>
  .loader {
    @apply py-2 px-5 flex items-center flex-col;
  }
  .default {
    @apply text-red-400;
  }
  .white {
    @apply text-white-100;
  }
  .text {
    @apply mt-4 text-sm italic text-gray-400;
  }
</style>

<script>
import uiIcon from '@/components/ui/uiIcon.vue';

export default {
  name: 'uiLoader',

  components: {
    uiIcon
  },

  props: {
    color: {
      type: String,
      default: 'default',
      validator: function (value) {
        return ['default', 'white'].indexOf(value) !== -1
      }
    },
    text: {
      type: String,
      default: null
    }
  }
}
</script>
