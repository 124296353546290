<template>
  <main-layout>
    <main>

      <ui-loader
        v-if="loading"
        class="self-center mt-9 sm:py-7"
      />

      <div v-else>
        <ui-heading>Souhrn objednávky</ui-heading>
        <ui-page-section-box
          tight
          :class="{ '-mx-4': this.$isMobile() }"
        >
          <div class="space-y-6">

            <div>
              <!-- PRODUCTS -->
              <h3 class="pt-6 mb-4 pl-4 sm:pl-6 font-semibold text-xl">Produkty</h3>
              <ui-grid>
                <ui-grid-item
                  v-for="item of cartData.items"
                  :key="item.id"
                >
                  <ui-grid-item-col class="w-10">
                    <ui-grid-item-col-content
                      @click="goToProductDetail(item)"
                      class="cursor-pointer"
                      :class="{ 'pr-2': this.$isMobile() }"
                    >
                      <img
                        :src="MEDIA_BASE_URL + item.product.images[0].file"
                        class="max-h-10"
                      >
                    </ui-grid-item-col-content>
                  </ui-grid-item-col>
                  <ui-grid-item-col class="flex-1 px-4">
                    <ui-grid-item-col-content
                      @click="goToProductDetail(item)"
                      class="cursor-pointer"
                    >
                      <h2 class="font-semibold">
                        {{ item.product.names[$store.getters['common/currentLanguage'].code] }}
                      </h2>
                      <small
                        v-for="(choice, index) of item.variants_choices"
                        :key="choice.id"
                        class="italic font-normal"
                      >
                        {{ choice.text }}<span v-if="index != (item.variants_choices.length - 1)">, </span>
                      </small>
                      <div v-if="this.$isMobile()">
                        <div class="text-sm text-bold">{{ $filters.price(item.total_pricesVAT, $store) }}</div>
                      </div>
                    </ui-grid-item-col-content>
                  </ui-grid-item-col>
                  <ui-grid-item-col class="w-40 text-right pr-4" v-if="!this.$isMobile()">
                    <ui-grid-item-col-content>
                      <div class="text-sm text-bold">{{ $filters.price(item.total_pricesVAT, $store) }}</div>
                    </ui-grid-item-col-content>
                  </ui-grid-item-col>
                </ui-grid-item>
              </ui-grid>
            </div>

            <div>
              <!-- CARRIER -->
              <h3 class=" pl-4 sm:pl-6 font-semibold text-xl">Způsob dopravy</h3>
              <ui-grid>
                <ui-grid-item>
                  <ui-grid-item-col class="flex-1">
                    <ui-grid-item-col-content>
                      {{ cartData.carrier.name }}
                      <div v-if="this.$isMobile()">
                        <div class="text-sm text-bold">{{ $filters.price(cartData.carrier.pricesVAT, $store) }}</div>
                      </div>
                    </ui-grid-item-col-content>
                  </ui-grid-item-col>
                  <ui-grid-item-col class="w-40 text-right pr-4" v-if="!this.$isMobile()">
                    <ui-grid-item-col-content>
                      <div class="text-sm text-bold">{{ $filters.price(cartData.carrier.pricesVAT, $store) }}</div>
                    </ui-grid-item-col-content>
                  </ui-grid-item-col>
                </ui-grid-item>
              </ui-grid>
            </div>

            <div>
              <!-- PAYMENT TYPE -->
              <h3 class="pl-4 sm:pl-6 font-semibold text-xl">Způsob platby</h3>
              <ui-grid>
                <ui-grid-item>
                  <ui-grid-item-col>
                    <ui-grid-item-col-content>
                      <template v-if="cartData.payment_type == 'CARD'">
                        Online platební kartou
                      </template>
                      <template v-else-if="cartData.payment_type == 'BANK'">
                        Bankovní převod
                      </template>
                      <template v-else>
                        {{ cartData.payment_type }}
                      </template>
                    </ui-grid-item-col-content>
                  </ui-grid-item-col>
                </ui-grid-item>
              </ui-grid>
            </div>

            <div>
              <!-- TOTALS -->
              <ui-grid class="mb-4">
                <ui-grid-item>
                  <ui-grid-item-col class="flex-1">
                    <ui-grid-item-col-content
                      :class="{
                        'pr-2': this.$isMobile(),
                        'text-right': !this.$isMobile()
                      }"
                    >
                      K úhradě:
                    </ui-grid-item-col-content>
                  </ui-grid-item-col>

                  <ui-grid-item-col class="w-40 text-right pr-4">
                    <ui-grid-item-col-content>
                      <div class="text-xl font-semibold text-red-400">{{ $filters.price(cartData.total_prices_incl_deliveryVAT, $store) }}</div>
                      <div class="text-xs font-normal text-gray-400">{{ $filters.price(cartData.total_prices_incl_delivery, $store) }} bez DPH</div>
                    </ui-grid-item-col-content>
                  </ui-grid-item-col>
                </ui-grid-item>
              </ui-grid>
            </div>

            <div
              class="p-6 mb-4"
              :class="{
                'text-center': this.$isMobile(),
                'text-right': !this.$isMobile()
              }"
            >
              <ui-button color="primary" @click="finishOrder()">Dokončit objednávku</ui-button>
            </div>
          </div>
        </ui-page-section-box>
      </div>
    </main>
  </main-layout>
</template>

<script>
import MainLayout from '@/components/layouts/MainLayout.vue';
import uiButton from '@/components/ui/uiButton.vue';
import uiGrid from '@/components/ui/uiGrid.vue';
import uiGridItem from '@/components/ui/uiGridItem.vue';
import uiGridItemCol from '@/components/ui/uiGridItemCol.vue';
import uiGridItemColContent from '@/components/ui/uiGridItemColContent.vue';
import uiHeading from '@/components/ui/uiHeading.vue';
import uiPageSectionBox from "@/components/ui/uiPageSectionBox.vue";
import uiLoader from '@/components/ui/uiLoader.vue';

export default {
  name: 'Cart',

  components: {
    MainLayout,
    uiHeading,
    uiPageSectionBox,
    uiGrid,
    uiGridItem,
    uiGridItemCol,
    uiGridItemColContent,
    uiButton,
    uiLoader
  },

  data: () => ({
    loading: true,
    cartData: null,
    MEDIA_BASE_URL: process.env.VUE_APP_PRODUCTS_MEDIA_BASE_URL
  }),

  created: function() {
    this.reload();
  },

  methods: {
    async reload() {
      this.loading = true;
      await this.$store.dispatch('cart/loadCartContent');
      this.cartData = this.$store.getters['cart/cartContent'];
      this.loading = false;
    },

    goToProductDetail(item) {
      this.$router.push({ name: 'ProductDetail', params: { id: item.product.id } });
      //OrderPayment
    },

    async finishOrder() {
      if (this.cartData.payment_type == 'CARD') {
        this.$router.push({ name: 'OrderPayment' });
      } else {
        await this.$store.dispatch('cart/finishOrder');
        this.$router.push({ name: 'OrderFinished' });
      }
    }    
  }
}
</script>
