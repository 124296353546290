import { createStore } from 'vuex';

import common from './common';
import products from './products';
import cart from './cart';

export default createStore({
  modules: {
    common: common,
    products: products,
    cart: cart
  },
  // enable strict mode (adds overhead!)
  // for dev mode only
  strict: process.env.DEV
});
