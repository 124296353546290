export default {
  price: (price, $store) => {
    let currency = $store.getters['common/currentCurrency'];
    var formatter = new Intl.NumberFormat('cs-CZ', {
      style: 'currency',
      currency: currency.code
    });
    return formatter.format(price[currency.code]);
  }
}
