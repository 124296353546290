<template>

  <svg v-if="name=='camera-on'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 0110.07 4h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0018.07 7H19a2 2 0 012 2v9a2 2 0 01-2 2H5a2 2 0 01-2-2V9z" />
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 13a3 3 0 11-6 0 3 3 0 016 0z" />
  </svg>

  <svg v-else-if="name=='camera-off'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 0110.07 4h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0018.07 7H19a2 2 0 012 2v9a2 2 0 01-2 2H5a2 2 0 01-2-2V9z" />
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 13a3 3 0 11-6 0 3 3 0 016 0z" />
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 24l15-22" />
  </svg>

  <svg v-else-if="name=='light-on'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z" />
  </svg>

  <svg v-else-if="name=='light-off'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z" />
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 22l15-22" />
  </svg>

  <svg v-else-if="name=='arrows-expand'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 8V4m0 0h4M4 4l5 5m11-1V4m0 0h-4m4 0l-5 5M4 16v4m0 0h4m-4 0l5-5m11 5l-5-5m5 5v-4m0 4h-4" />
  </svg>

  <svg v-else-if="name=='dots-vertical'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Dots" fill="currentColor">
        <rect id="Rectangle-Copy-3" x="11" y="5" width="3" height="3"></rect>
        <rect id="Rectangle-Copy-4" x="11" y="11" width="3" height="3"></rect>
        <rect id="Rectangle-Copy-5" x="11" y="17" width="3" height="3"></rect>
      </g>
    </g>
  </svg>

  <svg v-else-if="name=='search'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
  </svg>

  <svg v-else-if="name=='home'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
  </svg>

  <svg v-else-if="name=='slash'" class="flex-shrink-0 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
    <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
  </svg>

  <svg v-else-if="name=='user'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
  </svg>

  <svg v-else-if="name=='user-group'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
  </svg>

  <svg v-else-if="name=='lock-open'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 11V7a4 4 0 118 0m-4 8v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2z" />
  </svg>

  <svg v-else-if="name=='arrow-narrow-left'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16l-4-4m0 0l4-4m-4 4h18" />
  </svg>

  <svg v-else-if="name=='animate-spin-circle'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
  </svg>

  <svg v-else-if="name=='exclamation'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
  </svg>

  <svg v-else-if="name=='refresh'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
  </svg>

  <svg v-else-if="name=='cog'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
  </svg>

  <svg v-else-if="name=='information-circle'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
  </svg>

  <svg v-else-if="name=='chevron-down'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
  </svg>

  <svg v-else-if="name=='chevron-left'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
  </svg>

  <svg v-else-if="name=='chevron-right'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
  </svg>

  <svg v-else-if="name=='chevron-up'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
  </svg>

  <svg v-else-if="name=='directory'" xmlns="http://www.w3.org/2000/svg" fill="currentColor" width="768" height="768" viewBox="0 0 768 768" stroke="currentColor">
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M736 608v-352c0-26.496-10.784-50.56-28.128-67.872s-41.376-28.128-67.872-28.128h-270.88l-54.496-81.76c-5.824-8.64-15.552-14.24-26.624-14.24h-160c-26.496 0-50.56 10.784-67.872 28.128s-28.128 41.376-28.128 67.872v448c0 26.496 10.784 50.56 28.128 67.872s41.376 28.128 67.872 28.128h512c26.496 0 50.56-10.784 67.872-28.128s28.128-41.376 28.128-67.872zM672 608c0 8.832-3.552 16.8-9.376 22.624s-13.792 9.376-22.624 9.376h-512c-8.832 0-16.8-3.552-22.624-9.376s-9.376-13.792-9.376-22.624v-448c0-8.832 3.552-16.8 9.376-22.624s13.792-9.376 22.624-9.376h142.88l54.496 81.76c6.144 9.184 16.192 14.176 26.624 14.24h288c8.832 0 16.8 3.552 22.624 9.376s9.376 13.792 9.376 22.624z"></path>
  </svg>

  <svg v-else-if="name=='plus'" xmlns="http://www.w3.org/2000/svg" fill="currentColor" width="768" height="768" viewBox="0 0 768 768" stroke="currentColor">
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M607.5 415.5h-192v192h-63v-192h-192v-63h192v-192h63v192h192v63z"></path>
  </svg>

  <svg v-else-if="name=='cart'" xmlns="http://www.w3.org/2000/svg" fill="currentColor" width="768" height="768" viewBox="0 0 768 768" stroke="currentColor">
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M544.5 576q25.5 0 44.25 19.5t18.75 45-18.75 44.25-44.25 18.75-45-18.75-19.5-44.25 19.5-45 45-19.5zM31.5 64.5h105l30 63h474q13.5 0 22.5 9.75t9 23.25q0 1.5-4.5 15l-114 207q-18 33-55.5 33h-238.5l-28.5 52.5-1.5 4.5q0 7.5 7.5 7.5h370.5v64.5h-384q-25.5 0-44.25-19.5t-18.75-45q0-15 7.5-30l43.5-79.5-115.5-243h-64.5v-63zM223.5 576q25.5 0 45 19.5t19.5 45-19.5 44.25-45 18.75-44.25-18.75-18.75-44.25 18.75-45 44.25-19.5z"></path>
  </svg>

  <svg v-else-if="name=='cancel'" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 352 448" stroke="currentColor">
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M324.5 330.5c0 6.25-2.5 12.5-7 17l-34 34c-4.5 4.5-10.75 7-17 7s-12.5-2.5-17-7l-73.5-73.5-73.5 73.5c-4.5 4.5-10.75 7-17 7s-12.5-2.5-17-7l-34-34c-4.5-4.5-7-10.75-7-17s2.5-12.5 7-17l73.5-73.5-73.5-73.5c-4.5-4.5-7-10.75-7-17s2.5-12.5 7-17l34-34c4.5-4.5 10.75-7 17-7s12.5 2.5 17 7l73.5 73.5 73.5-73.5c4.5-4.5 10.75-7 17-7s12.5 2.5 17 7l34 34c4.5 4.5 7 10.75 7 17s-2.5 12.5-7 17l-73.5 73.5 73.5 73.5c4.5 4.5 7 10.75 7 17z"></path>
  </svg>

  <svg v-else-if="name=='bin'" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 512 512" stroke="currentColor">
    <path d="M64 160v320c0 17.6 14.4 32 32 32h288c17.6 0 32-14.4 32-32v-320h-352zM160 448h-32v-224h32v224zM224 448h-32v-224h32v224zM288 448h-32v-224h32v224zM352 448h-32v-224h32v224z"></path>
    <path d="M424 64h-104v-40c0-13.2-10.8-24-24-24h-112c-13.2 0-24 10.8-24 24v40h-104c-13.2 0-24 10.8-24 24v40h416v-40c0-13.2-10.8-24-24-24zM288 64h-96v-31.599h96v31.599z"></path>
  </svg>

  <svg v-else-if="name=='printer'" width="700pt" height="700pt" version="1.1" viewBox="0 0 700 700" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="currentColor">
  <g>
    <path d="m210 313.6h280v168h-280z"/>
    <path d="m210 263.2h280v33.602h-280z"/>
    <path d="m98 498.4h504v33.602h-504z"/>
    <path d="m389.2 179.2c0 18.531-15.07 33.602-33.602 33.602h-145.6v33.602h145.6c37.055 0 67.199-30.145 67.199-67.199z"/>
    <path d="m602 50.398h-145.6v-22.398h-100.8v22.398h-257.6v33.602h257.6v22.398l33.598 56h33.602l33.598-56v-22.398h145.6z"/>
    <use x="70" y="644" xlink:href="#v"/>
    <use x="90.550781" y="644" xlink:href="#c"/>
    <use x="104.359375" y="644" xlink:href="#a"/>
    <use x="123.347656" y="644" xlink:href="#j"/>
    <use x="142.242188" y="644" xlink:href="#d"/>
    <use x="155.628906" y="644" xlink:href="#a"/>
    <use x="174.617188" y="644" xlink:href="#i"/>
    <use x="204.410156" y="644" xlink:href="#h"/>
    <use x="224.453125" y="644" xlink:href="#g"/>
    <use x="252.453125" y="644" xlink:href="#f"/>
    <use x="271.550781" y="644" xlink:href="#b"/>
    <use x="290.789062" y="644" xlink:href="#u"/>
    <use x="309.40625" y="644" xlink:href="#a"/>
    <use x="338.148438" y="644" xlink:href="#t"/>
    <use x="357.273438" y="644" xlink:href="#c"/>
    <use x="371.082031" y="644" xlink:href="#a"/>
    <use x="390.070312" y="644" xlink:href="#e"/>
    <use x="399.671875" y="644" xlink:href="#e"/>
    <use x="409.265625" y="644" xlink:href="#b"/>
    <use x="70" y="672" xlink:href="#s"/>
    <use x="82.183594" y="672" xlink:href="#c"/>
    <use x="95.992188" y="672" xlink:href="#b"/>
    <use x="115.226562" y="672" xlink:href="#r"/>
    <use x="154.152344" y="672" xlink:href="#d"/>
    <use x="167.535156" y="672" xlink:href="#q"/>
    <use x="187.46875" y="672" xlink:href="#a"/>
    <use x="216.207031" y="672" xlink:href="#p"/>
    <use x="239.640625" y="672" xlink:href="#b"/>
    <use x="258.878906" y="672" xlink:href="#o"/>
    <use x="278.8125" y="672" xlink:href="#n"/>
    <use x="308.492188" y="672" xlink:href="#m"/>
    <use x="329.015625" y="672" xlink:href="#c"/>
    <use x="342.820312" y="672" xlink:href="#b"/>
    <use x="362.058594" y="672" xlink:href="#l"/>
    <use x="371.65625" y="672" xlink:href="#a"/>
    <use x="390.648438" y="672" xlink:href="#k"/>
    <use x="407.242188" y="672" xlink:href="#d"/>
  </g>
  </svg>

  <svg v-else-if="name=='youtube'" width="1024" height="1024" version="1.1" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="currentColor">
    <path d="M1002.453 264.747c-12.365-43.307-45.781-76.722-88.183-88.866l-0.905-0.222c-79.787-21.376-400.896-21.376-400.896-21.376s-320.299-0.427-400.896 21.376c-43.307 12.365-76.722 45.781-88.866 88.183l-0.222 0.905c-14.173 72.978-22.281 156.904-22.281 242.723 0 1.743 0.003 3.484 0.010 5.225l-0.001-0.268c-0.004 1.198-0.006 2.617-0.006 4.035 0 85.813 8.107 169.732 23.598 251.033l-1.32-8.327c12.365 43.307 45.781 76.722 88.183 88.866l0.905 0.222c79.701 21.419 400.896 21.419 400.896 21.419s320.256 0 400.896-21.419c43.307-12.365 76.722-45.781 88.866-88.183l0.222-0.905c13.59-71.472 21.364-153.686 21.364-237.715 0-3.173-0.011-6.344-0.033-9.513l0.003 0.486c0.024-2.951 0.037-6.439 0.037-9.929 0-84.041-7.776-166.267-22.648-245.996l1.278 8.245zM409.984 665.643v-306.901l267.264 153.685z"></path>
  </svg>

  <svg v-else-if="name=='facebook'" width="512" height="512" version="1.1" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="currentColor">
    <path d="M304 96h80v-96h-80c-61.757 0-112 50.243-112 112v48h-64v96h64v256h96v-256h80l16-96h-96v-48c0-8.673 7.327-16 16-16z"></path>
  </svg>

  <svg v-else-if="name=='twitter'" width="512" height="512" version="1.1" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="currentColor">
    <path d="M512 113.2c-18.8 8.4-39.1 14-60.3 16.5 21.7-13 38.3-33.6 46.2-58.1-20.3 12-42.8 20.8-66.7 25.5-19.2-20.4-46.5-33.1-76.7-33.1-58 0-105 47-105 105 0 8.2 0.9 16.2 2.7 23.9-87.3-4.4-164.7-46.2-216.5-109.8-9 15.5-14.2 33.6-14.2 52.8 0 36.4 18.5 68.6 46.7 87.4-17.2-0.5-33.4-5.3-47.6-13.1 0 0.4 0 0.9 0 1.3 0 50.9 36.2 93.4 84.3 103-8.8 2.4-18.1 3.7-27.7 3.7-6.8 0-13.3-0.7-19.8-1.9 13.4 41.7 52.2 72.1 98.1 73-36 28.2-81.2 45-130.5 45-8.5 0-16.8-0.5-25.1-1.5 46.6 29.9 101.8 47.2 161.1 47.2 193.2 0 298.9-160.1 298.9-298.9 0-4.6-0.1-9.1-0.3-13.6 20.5-14.7 38.3-33.2 52.4-54.3z"></path>
  </svg>

  <svg v-else-if="name=='github'" width="512" height="512" version="1.1" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="currentColor">
    <path d="M256.004 6.321c-141.369 0-256.004 114.609-256.004 255.999 0 113.107 73.352 209.066 175.068 242.918 12.793 2.369 17.496-5.555 17.496-12.316 0-6.102-0.24-26.271-0.348-47.662-71.224 15.488-86.252-30.205-86.252-30.205-11.641-29.588-28.424-37.458-28.424-37.458-23.226-15.889 1.755-15.562 1.755-15.562 25.7 1.805 39.238 26.383 39.238 26.383 22.836 39.135 59.888 27.82 74.502 21.279 2.294-16.543 8.926-27.84 16.253-34.232-56.865-6.471-116.638-28.425-116.638-126.516 0-27.949 10.002-50.787 26.38-68.714-2.658-6.45-11.427-32.486 2.476-67.75 0 0 21.503-6.876 70.42 26.245 20.418-5.674 42.318-8.518 64.077-8.617 21.751 0.099 43.668 2.943 64.128 8.617 48.867-33.122 70.328-26.245 70.328-26.245 13.936 35.264 5.175 61.3 2.518 67.75 16.41 17.928 26.347 40.766 26.347 68.714 0 98.327-59.889 119.975-116.895 126.312 9.182 7.945 17.362 23.523 17.362 47.406 0 34.254-0.298 61.822-0.298 70.254 0 6.814 4.611 14.797 17.586 12.283 101.661-33.888 174.921-129.813 174.921-242.884 0-141.39-114.617-255.999-255.996-255.999z"></path>
  </svg>

  <svg v-else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path fill="#000" d="M6 18L18 6M6 6l12 12" />
  </svg>

</template>

<script>
export default {
  name: 'uiIcon',
  props: ['name']
}
</script>
