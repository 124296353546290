<template>
  <ui-modal :show="show">
    <div class="space-y-6">
      <h1 class="text-xl font-semibold">Zabezpečená online platba</h1>

      <form id="payment-form" class="h-64">
        <div v-if="loading" class="flex h-full justify-center content-center">
          <ui-loader class="self-center" />
        </div>
        <div v-show="!loading" id="payment-element" class="h-52">
          ...stripe widget placeholder...
        </div>
      </form>

      <p class="text-xs">
        Platba probíhá pomocí certifikované platební brány
        <a href="https://stripe.com/" target="_blank">Stripe</a>.
        Společnost Fragaria s.r.o. nemá údaje o kartě uloženy, ani k nim nemá přístup.
      </p>

      <div class="text-center space-x-6 pt-4">
        <ui-button @click.stop="submitPayment();" :disabled="loading" color="primary">
          Zaplatit
          <template v-if="!loading || cartData?.total_prices_incl_deliveryVAT">{{ $filters.price(cartData.total_prices_incl_deliveryVAT, $store) }}</template>
        </ui-button>
        <ui-button @click.stop="close();" color="clear" :disabled="loading" class="">Zrušit</ui-button>
      </div>
    </div>
  </ui-modal>
</template>

<script>
import uiButton from '@/components/ui/uiButton.vue';
import uiLoader from '@/components/ui/uiLoader.vue';
import uiModal from '@/components/ui/uiModal.vue';

export default {
  name: 'PaymentModal',

  components: {
    uiButton,
    uiLoader,
    uiModal,
  },

  emits: [
    'close',
    'payment-result'
  ],

  props: {
    show: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    loading: true,
    paymentType: null,
    PAYMENT_TYPE_CARD: 'CARD',
    PAYMENT_TYPE_BANK: 'BANK',

    stripe: null,
    stripeElements: null,

    cartData: null
  }),

  created: async function() {
    // load card detail - total price needed on payment button
    await this.$store.dispatch('cart/loadCartContent');
    this.cartData = this.$store.getters['cart/cartContent'];

    // load Stripe external widget/library
    let tag = document.createElement('script');
    tag.addEventListener('load', () => {
      this.initStripe();
    });
    tag.setAttribute('src', 'https://js.stripe.com/v3/');
    document.head.appendChild(tag);
  },

  methods: {
    // init stripe payment form
    async initStripe() {
      // TODO: store key in env variable?
      this.stripe = window.Stripe('pk_live_51Ko4rKGulYQ6O0Woqg9FyocRp6hUUtxGfodNpKDuGDFYIhCRfw10tv4apBi0FzmhEr4jqoSDwJX5TLZPKylwn1S6000b0rI75W');

      let responseData = await this.$store.dispatch('cart/createPaymentIntent');
      let clientSecret = responseData['client_secret'];

      let appearance = {
        theme: 'flat',
        // labels: 'floating'
        variables: {
          // colorPrimary: '#0570de',
          // colorBackground: '#ffffff',
          // colorText: '#30313d',
          // colorDanger: '#df1b41',
          // fontFamily: 'Ideal Sans, system-ui, sans-serif',
          // spacingUnit: '2px',
          // borderRadius: '4px',
          // see all possible variables: https://stripe.com/docs/elements/appearance-api?platform=web#commonly-used-variables
        }
      };
      this.stripeElements = this.stripe.elements({ appearance, clientSecret });

      const paymentElement = this.stripeElements.create('payment');
      paymentElement.mount('#payment-element');

      this.loading = false;
    },

    async submitPayment() {
      this.loading = true;
      let paymentResponse = await this.stripe.confirmPayment({
        elements: this.stripeElements,
        confirmParams: {
          // TODO: get url from settings or generate automatically based on router?
          return_url: window.location.origin + this.$router.resolve({ name: 'OrderPayment' }).href,
        },
        redirect: 'if_required'
      });
      if (paymentResponse?.paymentIntent?.status == 'succeeded') {
        // payment successfull
        this.$emit('payment-result', 'SUCCESSFUL', paymentResponse);

      } else if (paymentResponse?.error?.type == 'validation_error') {
        // validation error - do nothing - error and what should be visible to user in payment form

      } else if (paymentResponse?.error) {
        // error
        this.$emit('payment-result', 'ERROR', paymentResponse);
        this.close();

      } else {
        // unknown response/result
        this.$emit('payment-result', 'UNKNOWN', paymentResponse);
        this.close();
      }

      // this.loading = false;
      this.loading = false;
    },

    close() {
      this.$emit('close');
    }
  }
}
</script>
