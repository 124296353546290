<template>
  <ui-teleport>
    <template v-if="show">
      <div class="menu-backdrop" @click="this.$emit('close')"></div>
      <div class="menu">
        <transition name="menuTransition">
          <div class="relative sm:border sm:border-t-2 border-gray-200 flex flex-col cursor-pointer shadow-md bg-white-100 -space-y-px z-10 rounded-sm">
            <div
              v-if="mobileTitle"
              class="
                sm:hidden
                px-4 pb-3 pt-5
                border-b-2
                font-semibold text-base text-black-900
                leading-5 break-words"
            >
              {{mobileTitle}}
            </div>
            <slot></slot>
          </div>
        </transition>
      </div>
    </template>
  </ui-teleport>
</template>

<style scoped>
.menuTransition-enter-active { @apply transition ease-out duration-200; }
.menuTransition-leave-active { @apply transition ease-in duration-75; }
.menuTransition-enter-from,
.menuTransition-leave-to { @apply transform opacity-0 scale-95; }
.menuTransition-enter-to,
.menuTransition-leave-from { @apply transform opacity-100 scale-100; }

.menu-backdrop {
  @apply
    fixed top-0 left-0 w-full h-full z-50 cursor-default
    bg-black-900 bg-opacity-50 sm:bg-opacity-0
    backdrop-filter backdrop-blur-sm sm:backdrop-blur-none;
}
.menu {
  @apply
    fixed right-0 bottom-0 w-full
    sm:absolute sm:top-7 sm:bottom-auto sm:w-auto;

  min-width: 10rem;
  z-index: 100;
}
</style>

<script>
import uiTeleport from '@/components/ui/uiTeleport.vue';

export default {
  name: 'uiContextMenu',
  components: {
    uiTeleport,
  },
  emits: ['close'],
  props: {
    show: {
      type: Boolean,
      default: false
    },
    mobileTitle: {
      type: String,
      default: null
    }
  },
  watch: {
    show: function() {
      this.show ? document.body.classList.add('overflow-hidden') : document.body.classList.remove('overflow-hidden')
      this.show ? document.body.classList.add('sm:overflow-auto') : document.body.classList.remove('sm:overflow-auto')
    }
  }
}
</script>
