<template>
  <main-layout>
    <main>
      <ui-loader
        v-if="loading"
        class="self-center mt-9 sm:py-7"
      />

      <div v-else>
        <ui-heading>{{ product.names[$store.getters['common/currentLanguage'].code] }}</ui-heading>
        <ui-page-section-box>
          <div 
            class="grid grid-cols-1 gap-8 lg:gap-y-0 md:grid-cols-2"
          >
            <div class="md:px-8">
              <carousel v-if="product?.images?.length > 0">
                <slide v-for="image of product.images" :key="image.file">
                  <div class="m-4">
                    <img class="max-h-80" :src="MEDIA_BASE_URL + image.file" :alt="image.title">
                  </div>
                </slide>
                <template #addons>
                  <navigation />
                  <pagination />
                </template>
              </carousel>
            </div>
            <div>
              <h2 class="font-semibold text-xl font-sans">
                {{ product.names[$store.getters['common/currentLanguage'].code] }}
              </h2>

              <div v-if="product.perex" class="pt-4">{{ product.perex }}</div>

              <product-add-to-cart :product="product" class="pt-4"></product-add-to-cart>

              <div class="pt-12" v-html="product.descriptions[$store.getters['common/currentLanguage'].code]"></div>
            </div>
          </div>
        </ui-page-section-box>
      </div>
    </main>
  </main-layout>
</template>

<style>
:root {
  --vc-nav-background-color: #ea272e;
  --vc-pgn-active-color: #ea272e;
  --vc-pgn-background-color: #d6d3d1;
}

.carousel__prev, .carousel__next,
.carousel__pagination-button
{
  border-radius: 0
}

.carousel__prev--in-active,
.carousel__next--in-active {
  display: none;
}
</style>

<script>
import MainLayout from '@/components/layouts/MainLayout.vue';
import uiHeading from '@/components/ui/uiHeading.vue';
import uiPageSectionBox from "@/components/ui/uiPageSectionBox.vue";
import ProductAddToCart from "@/components/ProductAddToCart.vue";
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
import uiLoader from '@/components/ui/uiLoader.vue';

export default {
  name: 'Products',

  components: {
    MainLayout,
    uiHeading,
    uiPageSectionBox,
    ProductAddToCart,
    Carousel,
    Slide,
    Pagination,
    Navigation,
    uiLoader
  },

  props: ['id'],

  data: () => ({
    loading: true,
    product: true,
    MEDIA_BASE_URL: process.env.VUE_APP_PRODUCTS_MEDIA_BASE_URL
  }),

  created: function() {
    this.product = this.$store.getters['products/productById'](this.id);
    if (this.product) {
      this.loading = false;
    } else {
      this.$store.dispatch('products/loadProducts').then(() => {
        this.product = this.$store.getters['products/productById'](this.id);
        if (!this.product) {
          this.$router.push({ name: '404', params: { catchAll: 'product-not-found' } });
        } else {
          this.loading = false;
        }
      });
    }
  },

}
</script>
