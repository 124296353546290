<template>
  <button type="button" class="btn" :class="[{'btn--sm':small}, color]" :disabled="disabled">
    <ui-icon v-if="icon && icon != ''" :name="icon" class="w-4" />
    <slot></slot>
  </button>
</template>

<style scoped>
  .btn {
    @apply
      inline-flex justify-center
      py-2 px-3
      border border-transparent
      shadow-sm rounded-sm
      text-sm font-medium
      focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-red-400
      disabled:opacity-50;
  }
  .btn--sm {
    @apply py-1 px-2 text-2xs;
  }
  .default {
    @apply
      text-gray-700 bg-white-100 border-gray-200
      hover:shadow hover:bg-gray-100;
  }
  .primary {
    @apply
      text-white-100 bg-red-400
      hover:bg-red-700;
  }
  .danger {
    @apply
      text-white-100 bg-red-400
      hover:bg-red-700
      focus:ring-red-400;
  }
  .dark {
    @apply
      bg-black-900 bg-opacity-100 text-white-100
      hover:bg-opacity-75;
  }
  .clear {
    @apply
      px-2
      bg-transparent border-transparent shadow-none
      hover:bg-white-100 hover:shadow-sm
      focus:ring-red-400;
  }
</style>

<script>
import uiIcon from '@/components/ui/uiIcon.vue';

export default {
  name: 'uiButton',

  components: {
    uiIcon
  },

  props: {
    icon: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: 'default',
      validator: function (value) {
        return [
          'default',
          'primary',
          'danger',
          'dark',
          'clear'
        ].indexOf(value) !== -1
      }
    }
  }
}
</script>
