<template>
  <a
    v-if="!to"
    class="
      flex items-center
      px-4 py-5 sm:p-4
      hover:bg-gray-100
      text-gray-700
      hover:text-red-400
      text-sm
    "
    :class="{'border-t border-t-1 border-gray-200': divider}"
    aria-current="page"
  >
    <span class="truncate">
      <slot></slot>
    </span>
  </a>
  <router-link
    :to="to"
    v-else-if="to"
    class="
      flex items-center
      px-4 py-5 sm:p-4
      hover:bg-gray-100
      text-gray-700
      hover:text-red-400
      text-sm
    "
    :class="{'border-t border-t-1 border-gray-200': divider}"
  >
    <span class="truncate">
      <slot></slot>
    </span>
  </router-link>
</template>

<script>
export default {
  name: 'uiContextMenuItem',
  props: {
    to: {
      type: Object,
      default: null
    },
    divider: {
      type: Boolean,
      default: false
    }
  }
}
</script>
