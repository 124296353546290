<template>
  <main-layout>
    <main>

      <ui-loader
        v-if="loading"
        class="self-center mt-9 sm:py-7"
      />

      <div v-else>
        <ui-heading>Doprava a platba</ui-heading>
        <ui-page-section-box
          :class="{'-mx-4': this.$isMobile()}"
        >

          <h2 class="font-semibold text-xl">Vyberte zemi doručení</h2>
          <div class="py-4">
            <ui-form-select
              id="destinationCountryId"
              v-model:value="destinationCountryId"
              :values="countries"
            ></ui-form-select>
          </div>

          <transition name="formAppear" appear>
            <div v-if="destinationCountryId">
              <h2 class="font-semibold text-xl mt-8">Zvolte způsob doručení</h2>

              <div
                v-for="carrier in destinationCountry.carriers"
                :key="carrier.id"
              >
                <!-- PACKETA DELIVERY TO ADDRESS -->
                <div v-if="carrier.type == 'PACKETA_ADDRESS'" class="pt-4">
                  <h3 class="font-semibold">
                    <input type="radio" :name="carrier.type" :id="carrier.type" class="mr-2" v-model="selectedCarrier" :value="carrier" />
                    <label :for="carrier.type" class="font-normal">
                      {{ carrier.name }} ({{ $filters.price(carrier.pricesVAT, $store) }})
                    </label>
                  </h3>
                  <transition name="formAppear" appear>
                    <p class="text-xs mt-1 sm:pl-6">Zásilka Vám bude doručena na adresu, kterou zadáte v následujícím kroku objednávky.</p>
                  </transition>
                </div>

                <!-- PACKETA DELIVERY TO PICKUP POINT -->
                <div v-if="carrier.type == 'PACKETA_POINT'" class="py-4">
                  <h3 class="font-semibold">
                    <input type="radio" :name="carrier.type" :id="carrier.type" class="mr-2" v-model="selectedCarrier" :value="carrier" />
                    <label :for="carrier.type" class="font-normal">
                      {{ carrier.name }} ({{ $filters.price(carrier.pricesVAT, $store) }})
                    </label>
                  </h3>
                  <transition name="formAppear" appear>
                    <div>
                      <div class="space-y-4 mt-1 sm:pl-6">
                        <p class="text-xs">Osobní odběr na některém z výdejních míst Zásilkovny.</p>

                        <div v-if="!packetaPickupPointData?.id">
                          <ui-button color="dark" small @click="selectedCarrier=carrier; openPacketaWidget()">
                            Vybrat výdejní místo
                          </ui-button>
                        </div>

                        <template v-else-if="packetaPickupPointData?.id && selectedCarrier?.type == carrier.type">
                          <div>
                            <div class="text-xs">Vámi zvolené výdejní místo:</div>
                            <div class="font-semibold">{{ packetaPickupPointData?.name }}</div>
                          </div>
                          <div class="flex content-center space-x-4">
                            <ui-button color="dark" small @click="openPacketaWidget()">
                              Změnit výdejní místo
                            </ui-button>
                            <a
                              :href="packetaPickupPointData?.url"
                              target="_blank"
                              class="text-xs inline-block self-center underline hover:no-underline"
                            >Zobrazit detail výdejního místa</a>
                          </div>
                        </template>
                      </div>
                    </div>
                  </transition>
                </div>

              </div>

            </div>
          </transition>

          <!-- PAYMENT TYPE -->
          <transition name="formAppear" appear>
            <div v-if="destinationCountryId && selectedCarrier && selectedCarrier && (selectedCarrier.type == 'PACKETA_ADDRESS' || (packetaPickupPointData != null && packetaPickupPointData?.id != null))">
              <h2 class="font-semibold text-xl mt-8">Zvolte způsob platby</h2>
              <div class="py-4">
                <div
                  v-for="ptype in paymentTypes"
                  :key="ptype.code"
                >
                  <h3 class="font-semibold">
                    <input type="radio" :name="'payment-type' + ptype.code" :id="'payment-type' + ptype.code" class="mr-1" v-model="paymentType" :value="ptype.code" />
                    <label :for="'payment-type' + ptype.code" class="font-normal">
                      <template v-if="ptype.code == 'CARD'">
                        Online platba kartou
                      </template>
                      <template v-else-if="ptype.code == 'BANK'">
                        Bankovní převod
                      </template>
                    </label>
                  </h3>

                  <p class="text-xs mt-1 mb-2 sm:pl-6">
                    <template v-if="ptype.code == 'CARD'">
                      Po dokončení objednávky budete přesměrování na online platební bránu.
                    </template>
                    <template v-else-if="ptype.code == 'BANK'">
                      Instrukce pro provedení bankovního převodu Vám zobrazíme po dokončení objednávky a pro jistotu zašleme i emailem.
                    </template>
                    <template v-else>{{ ptype.name }}</template>
                  </p>
                </div>
              </div>
            </div>
          </transition>

          <!-- CONTINUE -->
          <div class="mt-8 text-right">
            <div>
              <ui-button
                :disabled="!((selectedCarrier?.type=='PACKETA_POINT' && packetaPickupPointData != null && packetaPickupPointData?.id != null && packetaPickupPointData?.id?.length > 1) || selectedCarrier?.type=='PACKETA_ADDRESS') || paymentType == null"
                color="primary"
                @click="save()"
              >Pokračovat</ui-button>
            </div>
          </div>

        </ui-page-section-box>
      </div>
    </main>
  </main-layout>
</template>

<script>
import MainLayout from '@/components/layouts/MainLayout.vue';
import uiHeading from '@/components/ui/uiHeading.vue';
import uiPageSectionBox from "@/components/ui/uiPageSectionBox.vue";
import uiButton from '@/components/ui/uiButton.vue';
import uiFormSelect from '@/components/ui/uiFormSelect.vue';
import uiLoader from '@/components/ui/uiLoader.vue';

export default {
  name: 'Shipping',

  components: {
    MainLayout,
    uiHeading,
    uiPageSectionBox,
    uiButton,
    uiFormSelect,
    uiLoader
  },

  watch: {
    destinationCountryId(newVal, oldVal) {
      this.destinationCountry = this.$store.getters['cart/countryById'](this.destinationCountryId);
      // reset form data - only when destination country was already selected (not when initialy loaded data from API)
      if (oldVal != null) {
        this.deliveryType = null;
        this.packetaPickupPointData = {};
      }
    },
  },

  data: () => ({
    loading: true,
    cartData: null,
    destinationCountryId: null,
    destinationCountry: null,
    selectedCarrier: null,
    packetaPickupPointData: {},
    countries: null,

    paymentTypes: null,
    paymentType: null
  }),

  created: function() {
    // load external Packeta widget (then access it via window.Packeta...)
    let tag = document.createElement('script');
    tag.setAttribute('src', 'https://widget.packeta.com/v6/www/js/library.js');
    document.head.appendChild(tag);

    this.loadData();
  },

  methods: {
    async loadData() {
      this.loading = true;

      await this.$store.dispatch('cart/loadCountries');
      this.countries = this.$store.getters['cart/countries'];

      await this.$store.dispatch('common/loadPaymentTypes');
      this.paymentTypes = this.$store.getters['common/paymentTypes'];

      await this.$store.dispatch('cart/loadCartContent');
      this.cartData = this.$store.getters['cart/cartContent'];

      // cart is empty?
      if (this.cartData.items?.length <= 0) {
        // go back to cart
        this.$router.push({ name: 'Cart' });
        return;
      }

      if (this.cartData?.carrier) {
        this.destinationCountryId = this.cartData.carrier.country.id;
        this.selectedCarrier = this.cartData.carrier;
        this.packetaPickupPointData = this.cartData.carrier_extra_data;
      }

      this.paymentType = this.cartData?.payment_type;

      this.loading = false;
    },

    openPacketaWidget() {
      this.destinationCountry
      window.Packeta.Widget.pick(
        process.env.VUE_APP_PACKETA_API_KEY,
        this.onPacketaPickupPointEvent,
        {
          country: this.destinationCountry.code
        }
      );
    },

    onPacketaPickupPointEvent(eventData) {
      this.packetaPickupPointData = eventData;
    },

    async save() {
      this.loading = true;
      await this.setCartCarrier();
      await this.setPaymentType();

      this.$router.push({ name: 'OrderAddress' });
      this.loading = false;
    },

    async setCartCarrier() {
      let payload = {
        carrier_id: this.selectedCarrier.id,
        extra_data: this.packetaPickupPointData
      };
      await this.$store.dispatch('cart/setCarrier', payload);
    },

    async setPaymentType() {
      await this.$store.dispatch('cart/setPaymentType', { payment_type: this.paymentType });
    }
  }
}
</script>
