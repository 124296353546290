export function cartID () {
  return localStorage.getItem('cartID');
}

export function cartContent (state) {
  return state.cartContent;
}

export function countries (state) {
  return state.countries;
}

export function countryById (state) {
  return function (id) {
    return state.countries.find(x => x.id === id);
  }
}
