import httpClient from '@/httpClient';

export function loadCurrencies({ commit }) {
  let url = '/currencies';
  return httpClient.get(url).then(function (response) {
    commit('setCurrencies', response?.data);
  })
}

export function loadLanguages({ commit }) {
  let url = '/languages';
  return httpClient.get(url).then(function (response) {
    commit('setLanguages', response?.data);
  })
}

export function loadPaymentTypes({ commit }) {
  let url = '/payment-types';
  return httpClient.get(url).then(function (response) {
    commit('setPaymentTypes', response?.data);
  })
}
