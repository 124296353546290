export function setCurrencies(state, val) {
  state.currencies = Array.isArray(val) ? val : [];
}

export function setLanguages(state, val) {
  state.languages = Array.isArray(val) ? val : [];
}

export function setPaymentTypes(state, val) {
  state.paymentTypes = Array.isArray(val) ? val : [];
}

export function setCurrentLanguage(state, val) {
  state.currentLanguage = val;
  if (val) {
    localStorage.setItem('language', val.code);
  } else {
    localStorage.removeItem('language');
  }
}

export function setCurrentCurrency(state, val) {
  state.currentCurrency = val;
  if (val) {
    localStorage.setItem('currency', val.code);
  } else {
    localStorage.removeItem('currency');
  }
}
