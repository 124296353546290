<template>
  <notification-modal :show="validation.show" :notification="validation.notification" @close="validation.show = false;"></notification-modal>

  <main-layout>
    <main>

      <ui-loader
        v-if="loading"
        class="self-center mt-9 sm:py-7"
      />

      <div v-else>
        <ui-heading>Kontaktní a doručovací údaje</ui-heading>
        <ui-page-section-box>        
          <div class="max-w-lg">
            <!-- email / phone / newsletter -->
            <div>
              <h2 class="font-semibold text-xl">Kontaktní údaje</h2>
              <div class="mt-4">
                <div class="float-left inline">Email:</div>
                <ui-form-input
                  id="contact.email"
                  v-model:value="contact.email"
                  type="email"
                  small
                  class="w-full sm:w-96"
                  :required="true"
                />
              </div>
              <p class="text-xs mt-2">Na email Vám zašleme souhrn objednávky, fakturu a informace o doručení.</p>
            </div>

            <!-- invoicing address -->
            <div class="mt-12 space-y-4">
              <div>
                <h2 class="font-semibold text-xl">Fakturační adresa</h2>
                <p class="text-xs mt-2">
                  Tato adresa bude uvedena na faktuře, kterou Vám zašleme. V závisloti na zvoleném způsobu dopravy může být použita i jako adresa doručovací.
                  Pokud budete chtít zadat jinou doručovací adresu, můžete tak učinit ve formuláři níže.
                </p>
              </div>
              <div>
                <ui-form-checkbox
                  id="invoiceAddress.isCompany"
                  v-model:value="invoiceAddress.isCompany"
                  text="Nakupuji na firmu"
                />
              </div>
              <!-- company shopping -->
              <transition name="formAppear" appear>
                <div v-if="invoiceAddress.isCompany">
                  <div class="mt-4">
                    <div class="float-left inline">DIČ:</div>
                    <ui-form-input
                      id="invoiceAddress.vatNumber"
                      v-model:value="invoiceAddress.vatNumber"
                      type="text"
                      small
                      class="w-48"
                    />
                  </div>
                  <div class="mt-4">
                    <div class="float-left inline">Název společnosti:</div>
                    <ui-form-input
                      id="invoiceAddress.companyName"
                      v-model:value="invoiceAddress.companyName"
                      type="text"
                      small
                      class="w-64"
                    />
                  </div>
                </div>
              </transition>
              <div class="flex space-x-4">
                <div class="flex-1">
                  <div class="float-left inline">Jméno:</div>
                  <ui-form-input
                    id="invoiceAddress.firstName"
                    v-model:value="invoiceAddress.firstName"
                    type="text"
                    small
                    class="w-full"
                  />
                </div>
                <div class="flex-1">
                  <div class="float-left inline">Příjmení:</div>
                  <ui-form-input
                    id="invoiceAddress.lastName"
                    v-model:value="invoiceAddress.lastName"
                    type="text"
                    small
                    class="w-full"
                  />
                </div>
              </div>
              <div>
                <div class="float-left inline">Ulice a číslo popisné:</div>
                <ui-form-input
                  id="invoiceAddress.streetName"
                  v-model:value="invoiceAddress.streetName"
                  type="text"
                  small
                />
              </div>
              <div>
                <div class="float-left inline">PSČ:</div>
                <ui-form-input
                  id="invoiceAddress.postalCode"
                  v-model:value="invoiceAddress.postalCode"
                  type="text"
                  small
                  class="w-32"
                />
              </div>
              <div>
                <div class="float-left inline">Město:</div>
                <ui-form-input
                  id="invoiceAddress.city"
                  v-model:value="invoiceAddress.city"
                  type="text"
                  small
                  class="w-64"
                />
              </div>
              <div>
                <div>Země:</div>
                <ui-form-select
                  id="invoiceAddress.countryId"
                  v-model:value="invoiceAddress.countryId"
                  :values="countries"
                ></ui-form-select>
              </div>
              <p class="text-xs mt-2">Pokud potřebujete vystavit fakturu do jiné země, než je uvedena v seznamu, tak nás prosím kontaktujte na <a href="mailto:karmen@karmen.tech">karmen@karmen.tech</a>.</p>
            </div>

            <!-- delivery information -->
            <div class="mt-12">
              <h2 class="font-semibold text-xl">Doručovací adresa</h2>

              <!-- PACKETA_POINT -->
              <div v-if="cartData?.carrier?.type == 'PACKETA_POINT'" class="space-y-4 mt-2">
                <div>
                  <div>
                    <div class="text-xs">Vaše objednávka bude doručována na výdejní místo:</div>
                    <div class="font-semibold">{{ cartData?.carrier_extra_data?.name }}</div>
                  </div>
                  <div class="flex content-center space-x-4 mt-2">
                    <ui-button color="dark" small @click.stop="$router.push({ name: 'Shipping' });">
                      Změnit
                    </ui-button>
                    <a
                      :href="cartData?.carrier_extra_data?.url"
                      target="_blank"
                      class="text-xs inline-block self-center underline hover:no-underline"
                    >Zobrazit detail výdejního místa</a>
                  </div>
                </div>
              </div>

              <!-- PACKETA_ADDRESS -->
              <div v-else>
                <div class="mt-4">
                  <div>
                    <input type="radio" name="diffDeliveryAddress" id="diffDeliveryAddress" class="mr-2" v-model="deliveryAddress.differFromInvoiceAddress" :value="false" />
                    <label for="diffDeliveryAddress" class="font-normal">Doručovací adresa je stejná jako fakturační adresa</label>
                  </div>
                  <div>
                    <input type="radio" name="sameDeliveryAddress" id="sameDeliveryAddress" class="mr-2" v-model="deliveryAddress.differFromInvoiceAddress" :value="true" />
                    <label for="sameDeliveryAddress" class="font-normal">Doručovací adresa je jiná než fakturační adresa</label>
                  </div>
                </div>

                <transition name="formAppear" appear>
                  <div v-if="deliveryAddress.differFromInvoiceAddress" class="mt-4">
                    <div class="flex">
                      <div class="mt-4">
                        <div class="float-left inline">Jméno:</div>
                        <ui-form-input
                          id="deliveryAddress.firstName"
                          v-model:value="deliveryAddress.firstName"
                          type="text"
                          small
                          class="w-64"
                        />
                      </div>
                      <div class="mt-4 ml-4">
                        <div class="float-left inline">Příjmení:</div>
                        <ui-form-input
                          id="deliveryAddress.lastName"
                          v-model:value="deliveryAddress.lastName"
                          type="text"
                          small
                          class="w-64"
                        />
                      </div>
                    </div>
                    <div class="mt-4">
                      <div class="float-left inline">Ulice a číslo popisné:</div>
                      <ui-form-input
                        id="deliveryAddress.streetName"
                        v-model:value="deliveryAddress.streetName"
                        type="text"
                        small
                        class="w-auto"
                      />
                    </div>
                    <div class="mt-4">
                      <div class="float-left inline">PSČ:</div>
                      <ui-form-input
                        id="deliveryAddress.postalCode"
                        v-model:value="deliveryAddress.postalCode"
                        type="text"
                        small
                        class="w-32"
                      />
                    </div>
                    <div class="mt-4">
                      <div class="float-left inline">Město:</div>
                      <ui-form-input
                        id="deliveryAddress.city"
                        v-model:value="deliveryAddress.city"
                        type="text"
                        small
                        class="w-64"
                      />
                    </div>
                    <div class="mt-4">
                      <div>Země:</div>
                      <ui-form-select
                        id="deliveryAddress.countryId"
                        v-model:value="deliveryAddress.countryId"
                        :values="countries"
                        disabled
                      ></ui-form-select>
                      <p class="text-xs mt-2">Země doručení je pevně nastavena dle Vámi zvoleného způsobu doručení v předchozím kroku objednávky.</p>
                    </div>
                  </div>
                </transition>
              </div>

              <div class="mt-12">
                <div class="float-left inline">Telefon:</div>
                <ui-form-input
                  id="deliveryAddress.deliveryPhone"
                  v-model:value="deliveryAddress.deliveryPhone"
                  type="text"
                  small
                  class="w-64"
                />
                <p class="text-xs mt-2">Pomocí SMS a telefonu budete informování o stavu doručení a způsobu vyzvednutí.</p>
              </div>

            </div>
          </div>

          <div class="mt-8 text-right">
            <div>
              <ui-button
                color="primary"
                @click="saveInvoiceAddress()"
              >Pokračovat</ui-button>
            </div>
          </div>
        </ui-page-section-box>
      </div>
    </main>
  </main-layout>
</template>

<script>
import MainLayout from '@/components/layouts/MainLayout.vue';
import uiHeading from '@/components/ui/uiHeading.vue';
import uiPageSectionBox from "@/components/ui/uiPageSectionBox.vue";
import uiFormInput from '@/components/ui/uiFormInput.vue';
import uiFormCheckbox from '@/components/ui/uiFormCheckbox.vue';
import uiButton from '@/components/ui/uiButton.vue';
import uiFormSelect from '@/components/ui/uiFormSelect.vue';
import NotificationModal from '@/components/NotificationModal.vue';
import uiLoader from '@/components/ui/uiLoader.vue';

export default {
  name: 'OrderAddress',

  components: {
    MainLayout,
    uiHeading,
    uiPageSectionBox,
    uiFormInput,
    uiFormCheckbox,
    uiButton,
    uiFormSelect,
    NotificationModal,
    uiLoader
  },

  data: () => ({
    loading: true,
    countries: null,
    cartData: null,
    contact: {
      email: null
    },
    invoiceAddress: {
      isCompany: false,
      vatNumber: null,
      companyName: null,
      firstName: null,
      lastName: null,
      streetName: null,
      postalCode: null,
      city: null,
      countryId: null,
    },
    deliveryAddress: {
      differFromInvoiceAddress: false,
      firstName: null,
      lastName: null,
      streetName: null,
      postalCode: null,
      city: null,
      countryId: null,
      deliveryPhone: '+420'
    },
    validation: {
      notification: {
        title: null,
        text: null
      },
      show: false
    }
  }),

  created: function() {
    this.init();
  },

  methods: {
    async init() {
      await this.$store.dispatch('cart/loadCartContent');
      this.cartData = this.$store.getters['cart/cartContent'];

      if (
        this.cartData.carrier == null  // carrier has to be selected first
        || this.cartData.carrier?.id?.length <= 1
        || this.cartData.items?.length <= 0  // cart is empty
      ) {
        // go back to shipping
        this.$router.push({ name: 'Shipping' });
        return;
      }

      await this.$store.dispatch('cart/loadCountries');
      this.countries = this.$store.getters['cart/countries'];

      // set form data from existing cart order
      this.contact.email = this.cartData.email
      this.invoiceAddress.vatNumber = this.cartData.invoice_vat_id;
      this.invoiceAddress.companyName = this.cartData.invoice_company;
      this.invoiceAddress.firstName = this.cartData.invoice_first_name;
      this.invoiceAddress.lastName = this.cartData.invoice_last_name;
      this.invoiceAddress.streetName = this.cartData.invoice_street;
      this.invoiceAddress.postalCode = this.cartData.invoice_zip_code;
      this.invoiceAddress.city = this.cartData.invoice_city;
      this.invoiceAddress.countryId = this.cartData.invoice_country?.id;

      this.deliveryAddress.differFromInvoiceAddress = !this.cartData.delivery_as_invoice;
      this.deliveryAddress.firstName = this.cartData.delivery_first_name;
      this.deliveryAddress.lastName = this.cartData.delivery_last_name;
      this.deliveryAddress.streetName = this.cartData.delivery_street;
      this.deliveryAddress.postalCode = this.cartData.delivery_zip_code;
      this.deliveryAddress.city = this.cartData.delivery_city;
      this.deliveryAddress.countryId = this.cartData.delivery_country?.id;
      this.deliveryAddress.deliveryPhone = this.cartData.delivery_delivery_phone;

      // is order made by company?
      if (this.cartData.invoice_vat_id?.length > 0 || this.cartData.invoice_company?.length > 0) {
        this.invoiceAddress.isCompany = true;
      }

      // force set delivery address based on selected carrier country
      this.deliveryAddress.countryId = this.cartData.carrier?.country?.id;

      this.loading = false;
    },

    async saveInvoiceAddress() {
      if (this.validateForm()) {
        this.loading = true;
        let payload = {};

        // contact email
        payload.email = this.contact.email;

        // invoice data
        payload.invoice_vat_id = this.invoiceAddress.vatNumber;
        payload.invoice_company = this.invoiceAddress.companyName;
        payload.invoice_first_name = this.invoiceAddress.firstName;
        payload.invoice_last_name = this.invoiceAddress.lastName;
        payload.invoice_street = this.invoiceAddress.streetName;
        payload.invoice_zip_code = this.invoiceAddress.postalCode;
        payload.invoice_city = this.invoiceAddress.city;
        payload.invoice_country_id = this.invoiceAddress.countryId;

        // delivery data
        payload.delivery_as_invoice = !this.deliveryAddress.differFromInvoiceAddress;
        // if delivery address same as invoice, don't save it
        if (!payload.delivery_as_invoice) {
          payload.delivery_first_name = this.deliveryAddress.firstName;
          payload.delivery_last_name = this.deliveryAddress.lastName;
          payload.delivery_street = this.deliveryAddress.streetName;
          payload.delivery_zip_code = this.deliveryAddress.postalCode;
          payload.delivery_city = this.deliveryAddress.city;
          payload.delivery_country_id = this.deliveryAddress.countryId;
        }
        payload.delivery_delivery_phone = this.deliveryAddress.deliveryPhone;

        await this.$store.dispatch('cart/setOrderAddresses', payload);

        this.$router.push({ name: 'OrderSummary' });

        this.loading = false;
      }
    },

    validateForm() {
      this.validation.notification.title = 'Chyba vyplněných údajů';
      this.validation.notification.text = '';

      let problems = [];

      // is it company order? check at least company name
      if (this.invoiceAddress.isCompany) {
        !this.invoiceAddress.companyName ? problems.push('název společnosti <small>(fakturační adresa)</small>') : '';
      }

      // check email (https://ui.dev/validate-email-address-javascript)
      !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.contact.email) ? problems.push('email') : '';

      // check invoice address
      !this.invoiceAddress.firstName ? problems.push('jméno <small>(fakturační adresa)</small>') : '';
      !this.invoiceAddress.lastName ? problems.push('příjmení <small>(fakturační adresa)</small>') : '';
      !this.invoiceAddress.streetName ? problems.push('ulice <small>(fakturační adresa)</small>') : '';
      !this.invoiceAddress.postalCode ? problems.push('PSČ <small>(fakturační adresa)</small>') : '';
      !this.invoiceAddress.city ? problems.push('město <small>(fakturační adresa)</small>') : '';
      !this.invoiceAddress.countryId ? problems.push('země <small>(fakturační adresa)</small>') : '';

      // check delivery address if selected
      if (this.deliveryAddress.differFromInvoiceAddress) {
        !this.deliveryAddress.firstName ? problems.push('jméno <small>(doručovací adresa)</small>') : '';
        !this.deliveryAddress.lastName ? problems.push('příjmení <small>(doručovací adresa)</small>') : '';
        !this.deliveryAddress.streetName ? problems.push('ulice <small>(doručovací adresa)</small>') : '';
        !this.deliveryAddress.postalCode ? problems.push('PSČ <small>(doručovací adresa)</small>') : '';
        !this.deliveryAddress.city ? problems.push('město <small>(doručovací adresa)</small>') : '';
        !this.deliveryAddress.countryId ? problems.push('země <small>(doručovací adresa)</small>') : '';
      }

      // always check delivery phone
      !this.deliveryAddress.deliveryPhone ? problems.push('telefonní číslo') : '';

      if (problems.length > 0) {
        problems.forEach(p => {
          this.validation.notification.text += '- ' + p + '<br>';
        });

        this.validation.show = true;
        return false;
      } else {
        this.validation.show = false;
        return true;
      }
    }
  }
}
</script>
