<template>
  <teleport to="#modal" v-if="this.$isMobile()">
    <slot></slot>
  </teleport>
  <template v-else-if="!this.$isMobile()">
    <slot></slot>
  </template>
</template>

<script>

export default {
  name: 'uiTeleport',
}
</script>
