<template>
  <li class="border-b border-1 border-gray-200 last:border-0">
    <a
      :class="{'cursor-pointer hover:bg-gray-150': !loading && !header && clickable}"
      class="group block"
    >
      <div class="pl-4 pr-1 py-2 sm:pl-6 sm:pr-3 flex items-center text-sm">
        <div class="min-w-0 flex flex-1 items-center justify-between">
          <slot></slot>
        </div>
      </div>
    </a>
  </li>
</template>

<script>
export default {
  name: 'uiGridItem',
  props: {
    header: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    clickable: {
      type: Boolean,
      default: false
    }
  }
}
</script>
