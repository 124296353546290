<template>
  <template v-if="!loading">
    <router-view />
  </template>
  <template v-else>
    <ui-loader
      v-if="loading"
      class="self-center mt-9 sm:py-7"
    />
  </template>
</template>

<script>
import uiLoader from '@/components/ui/uiLoader.vue';

export default {
  name: 'App',

  components: {
    uiLoader
  },

  data: () => ({
    loading: true
  }),

  created: async function() {
    // load and set current language
    await this.$store.dispatch('common/loadLanguages');
    let langCode = this.$store.getters['common/currentLanguageCode'];
    if (langCode) {
      this.$store.commit('common/setCurrentLanguage', this.$store.getters['common/languageByCode'](langCode));
    } else {
      this.$store.commit('common/setCurrentLanguage', this.$store.getters['common/languages'][0]);
    }

    // load and set current currency
    await this.$store.dispatch('common/loadCurrencies');
    let currCode = this.$store.getters['common/currentCurrencyCode'];
    if (currCode) {
      this.$store.commit('common/setCurrentCurrency', this.$store.getters['common/currencyByCode'](currCode));
    } else {
      this.$store.commit('common/setCurrentCurrency', this.$store.getters['common/currencies'][0]);
    }

    // check current cartID and create one if not exists (or invalid) in local storage
    await this.$store.dispatch('cart/loadCartID', this.$store.getters['cart/cartID']);

    this.loading = false;
  }
}
</script>
