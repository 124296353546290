<template>
  <div>
    <label :for="id" class="block text-sm font-medium text-gray-700 leading-7 cursor-pointer select-none">
      {{ labelText }}
    </label>

      <input
        v-bind:value="value"
        v-on:input="$emit('update:value', $event.target.value)"
        :id="id"
        :name="id"
        :type="type"
        :required="required"
        :disabled="disabled"
        :min="min"
        class="
          appearance-none
          block w-full
          border border-gray-200 rounded-sm
          bg-white-100
          placeholder-gray-400
          focus:outline-none focus:ring-red-400 focus:border-transparent
          text-sm
        "
        :class="{
          'bg-gray-100 text-gray-700 italic': disabled,
          'px-3 py-2': !small,
          'px-2 py-1': small,
        }"
      />

    <em v-if="messageText" class="block mt-1.5 text-sm">{{ messageText }}</em>
  </div>
</template>

<script>
export default {
  name: 'uiFormInput',

  props: {
    id: {
      type: String,
      default: null,
      required: true
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      default: null
    },
    labelText: {
      type: String,
      default: null
    },
    messageText: {
      type: String,
      default: null
    },
    min: {
      type: Number,
      default: null
    },
    small: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'text',
      validator: function (value) {
        return [
          'text',
          'email',
          'password',
          'number',
          'radio'
        ].indexOf(value) !== -1
      }
    }
  },

  emits: ['update:value']
}
</script>
