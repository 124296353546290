<template>
  <div v-if="product">
    <!-- optional product variants -->
    <div class="md:flex md:space-x-4">
      <div
        v-for="(variant, index) of product.variants"
        :key="variant.id"
        class="mt-4 w-full"
      >
        <ui-form-select
          :labelText="variant.name"
          :id="'variant-' + variant.id"
          :required="false"
          v-model:value="cartForm.variants[index]"
          :values="variant.choices"
          :allow-empty="true"
        ></ui-form-select>
      </div>
    </div>

    <dl class="grid grid-cols-3 mt-4">
      <!-- product stock/availibility -->
      <dt>Skladová dostupnost:</dt>
      <dd class="col-span-2 text-left">
        <div v-if="product.stock > 0">
          více než 5 kusů
        </div>
        <div v-else class="text-xs font-normal text-gray-400 pt-1 pb-2">
          Produkt je aktuálně nedostupný. Pokud o něj máte zájem, tak nás prosím <a :href="websiteOriginUrl + '/cs/contact/'" class="bm-item">kontaktujte</a>.
        </div>
      </dd>

      <!-- product price -->
      <dt >Cena:</dt>
      <dd class="col-span-2 text-left">
        <div class="text-2xl text-red-400 font-semibold">
          {{ $filters.price(product.pricesVAT, $store) }}
        </div>
      </dd>

      <dt class="text-gray-400">Cena bez DPH:</dt>
      <dd class="col-span-2 text-left text-gray-400">
        {{ $filters.price(product.prices, $store) }}
      </dd>

    </dl>

    <!-- add to cart button -->
    <div class="mt-4">
      <ui-button
        color="primary"
        @click="buy()"
        :disabled="product.stock <= 0"
      >Koupit</ui-button>
    </div>

  </div>
</template>

<script>
import uiFormSelect from '@/components/ui/uiFormSelect.vue';
import uiButton from '@/components/ui/uiButton.vue';

export default {
  name: 'ProductAddToCart',

  components: {
    uiFormSelect,
    uiButton
  },

  props: ['product'],

  data: () => ({
    cartForm: {
      variants: []
    },
    websiteOriginUrl: process.env.VUE_APP_WEBSITE_ORIGIN_URL
  }),

  methods: {
    buy() {
      // filter valid only choices
      let choices = [];
      this.cartForm.variants.forEach((choice) => {
        if (choice && choice != '') {
          choices.push(choice);
        }
      });
      this.$store.dispatch('cart/addToCart', {
        product_id: this.product.id,
        quantity: 1,
        variants_choices: choices
      }).then(() => {
        this.$router.push({ name: 'Cart' });
      });
    },
  }
}
</script>
