<template>
  <main-layout>
    <main>

      <ui-loader
        v-if="loading"
        class="self-center mt-9 sm:py-7"
      />

      <div v-else>
        <ui-heading>Produkty</ui-heading>
        <ui-page-section-box>
          <div
            class="
              grid grid-cols-1 gap-x-4 gap-y-8 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4
              cursor-pointer
            "
          >
            <div
              v-for="(item, index) in products"
              :key="item.id"
              @click="goToProductDetail(item);"
              class="
                flex flex-col justify-between
                py-6 md:py-0 md:px-4 first:pl-0 first:pt-0 last:pb-0
              "
              :class="{'border-l border-gray-150': index%4 > 0}"
            >
              <img v-if="item.images?.length > 0" :src="MEDIA_BASE_URL + item.images[0].file" class="my-4 mx-8" />
              <div>
                  <h2 class="font-semibold text-sm font-sans">
                    {{ item.names[$store.getters['common/currentLanguage'].code] }}
                  </h2>
                  <div class="flex justify-between pt-4">
                      <div>
                        <div class="text-lg text-red-400 font-semibold">{{ $filters.price(item.pricesVAT, $store) }}</div>
                        <div class="text-xs text-gray-400">{{ $filters.price(item.prices, $store) }} bez DPH</div>
                      </div>
                      <div>
                        <ui-button small color="primary">Koupit</ui-button>
                      </div>
                  </div>
              </div>
            </div>
          </div>
        </ui-page-section-box>
      </div>
    </main>
  </main-layout>
</template>

<script>
import MainLayout from '@/components/layouts/MainLayout.vue';
import uiButton from '@/components/ui/uiButton.vue';
import uiHeading from '@/components/ui/uiHeading.vue';
import uiPageSectionBox from "@/components/ui/uiPageSectionBox.vue";
import uiLoader from '@/components/ui/uiLoader.vue';

export default {
  name: 'Products',

  components: {
    MainLayout,
    uiButton,
    uiHeading,
    uiPageSectionBox,
    uiLoader
  },

  data: () => ({
    loading: true,
    products: true,
    MEDIA_BASE_URL: process.env.VUE_APP_PRODUCTS_MEDIA_BASE_URL
  }),

  created: function() {
    this.$store.dispatch('products/loadProducts').then(() => {
      this.loading = false;
      this.products = this.$store.getters['products/products'];
    });
  },

  methods: {
    goToProductDetail(item) {
      this.$router.push({ name: 'ProductDetail', params: { id: item.id } });
    },
  }
}
</script>
